import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import styled from "styled-components"
import { BgImage, Container, Layout, Section } from "../components"
import SEO from "../components/seo"

const variants = {
  hidden: { scale: 1.2, opacity: 0, y: "-200%" },
  visible: { scale: 1, opacity: 1, y: 0 },
}

const IndexPage = props => {
  const { data } = props

  return (
    // Add transparentHeader prop to Layout component if desired
    <Layout transparentHeader>
      <SEO title="Home" description="This is a description" />
      {/* Don't Need a Section if using BgImage Component */}
      <BgImage
        fluid={data.desktop.childImageSharp.fluid}
        height="100vh"
        mobileHeight="100vh"
        title="desktop"
        overlayColor="rgba(0, 0, 0, .4)"
        css={`
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <Container>
          <div style={{ position: "relative" }}>
            {/* <GlassMorphism2 /> */}
            <GlassMorphism>
              <div>
                <Headline
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ duration: 0.8 }}
                >
                  Bonafide Pixel Pusher
                </Headline>
                <SubHeadline
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ delay: 0.4, duration: 0.8 }}
                >
                  I am an always curious, strategic-creative digital creator
                </SubHeadline>
              </div>
            </GlassMorphism>
          </div>
        </Container>
      </BgImage>
      {/* Add backgroundColor="#333333" if desired */}
      <Section backgroundColor="var(--gray-800)" paddingTop={"4rem"}>
        <Container>
          <p
            css={`
              color: var(--gray-300);
            `}
          >
            This is a happy place, little squirrels live here and play. It's
            almost like something out of a fairytale book. The man who does the
            best job is the one who is happy at his job. I was blessed with a
            very steady hand; and it comes in very handy when you're doing these
            little delicate things.
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

const GlassMorphism = styled.div`
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.37);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 5000px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  min-height: 380px;

  max-width: 370px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  padding: 1rem;
  @media (min-width: ${props => props.theme.responsive.md}) {
    max-width: 470px;
    min-height: 470px;
  }
`
const GlassMorphism2 = styled(GlassMorphism)`
  position: absolute;
  height: 270px;
  width: 270px;
  top: 230px;
  min-height: initial;
  @media (min-width: ${props => props.theme.responsive.md}) {
    top: 66px;
    left: 201px;
    width: 470px;
    height: 470px;
  }
`

const Headline = styled(motion.h1)`
  margin-top: 0;
  color: var(--accent);
  margin-bottom: 0;
  font-family: Montserrat;
  font-size: var(--text-2xl);
  line-height: 1.2em;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  @media (min-width: ${props => props.theme.responsive.sm}) {
    text-align: center;
    font-size: var(--text-4xl);
  }
`

const SubHeadline = styled(motion.h2)`
  margin: 0;
  margin-top: 1rem;
  color: var(--primary-light);
  font-size: var(--text-lg);
  letter-spacing: 0.05em;
  text-align: center;
  @media (min-width: ${props => props.theme.responsive.sm}) {
    font-size: var(--text-xl);
    text-align: center;
  }
`

export const pageQuery = graphql`
  query {
    desktop: file(relativePath: { eq: "pdx-background.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
